@import './css/_variables';

@font-face {
  font-family: 'BCSans';
  src: url('./fonts/BCSans3.ttf') format('truetype');
}

@font-face {
  font-family: 'BCSans';
  src: url('./fonts/BCSans3-Bold.ttf') format('truetype');
  font-weight: bold;
}

.spinner {
  color: $btn-bk-primary;
}

::selection {
  background: yellow;
}

html {
  font-size: 14px;
  font-weight: 500;
  font-family: $f-primary;
}

.table-container {
  font-family: $f-primary;
}

input[type='checkbox'],
input[type='radio'] {
  accent-color: $btn-bk-primary;
}

.toggle-item.active {
  background-color: $btn-bk-primary;
  color: $btn-primary-color;
}

.toggle-item:not(.active) {
  background-color: $btn-primary-color;
}

body {
  margin: 0;
  overflow: clip;
  max-height: 100dvh;
}

main {
  overflow: clip;
}

body,
button,
input,
textarea {
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  &.warning {
    color: $btn-yellow-color;
    background-color: $btn-yellow-bk-color;
    border-color: $btn-yellow-color;
  }

  &.danger {
    color: $btn-red-color;
    background-color: $btn-red-bk-color;
    border-color: $btn-red-color;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  margin: 0.5em 0 0.5em 0;
}

h1 {
  font-size: 2rem;
  margin-top: 0;
}

h2 {
  font-size: 1.25rem;
  margin-top: 0;
}

h3 {
  font-size: 1rem;
  margin-top: 0;
}

h4 {
  font-size: 0.75rem;
  margin-top: 0;
}

.caps {
  text-transform: uppercase;
}

// *************************************************
// Tooltip Customization
// *************************************************
.type-light.place-top {
  padding: 2px 5px 2px 5px;
  border: solid 1px $line-primary-color;

  &:after {
    border-top: 5px solid $line-primary-color !important;
  }
}

.type-light.place-left {
  padding: 2px 5px 2px 5px;
  border: solid 1px $line-primary-color;

  &:after {
    border-left: 5px solid $line-primary-color !important;
  }
}

.type-light.place-right {
  padding: 2px 5px 2px 5px;
  border: solid 1px $line-primary-color;

  &:after {
    border-right: 5px solid $line-primary-color !important;
  }
}

.type-light.place-bottom {
  padding: 2px 5px 2px 5px;
  border: solid 1px $line-primary-color;

  &:after {
    border-bottom: 5px solid $line-primary-color !important;
  }
}

// *************************************************
// Forms
// *************************************************
.frm-in {
  padding-bottom: 0.5em;

  label {
    padding-right: 0.5em;
    font-weight: bold;
  }

  &.chk {
    padding-bottom: 0em;

    label {
      cursor: pointer;
    }
  }

  &.chg {
    padding-bottom: 0;

    label:not(:first-child) {
      display: inline;
      font-weight: normal;
    }
  }

  &.rag {
    label:not(:first-child) {
      display: inline;
      font-weight: normal;
    }
  }
}

.error {
  color: red;
}

.btn-link {
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.align-right {
  text-align: right;

  input {
    text-align: right;
  }
}
